const React = require('react');
const { shape, func, string } = require('prop-types');

const mapComponents = (components, spot) => {
  const componentsToRender = [];
  Object.values(components).forEach(value => {
    if (value && value.dynamic_component && value.dynamic_component.spot === spot) {
      componentsToRender.push(value);
    }
  });
  return componentsToRender.sort((a, b) => a.dynamic_component.order - b.dynamic_component.order);
};

const DynamicComponentWrapper = ({ components, renderDynamicComponent, spot, className }) => {
  const componentsToRender = mapComponents(components, spot);
  const content = componentsToRender.map(({ component_id, id }, index, arr) => (
    <React.Fragment key={`${component_id}-${id}`}>
      {renderDynamicComponent(component_id || id, index, arr.length)}
    </React.Fragment>
  ));
  return <div className={className}>{content}</div>;
};

DynamicComponentWrapper.propTypes = {
  components: shape().isRequired,
  renderDynamicComponent: func,
  spot: string,
  className: string,
};

module.exports = DynamicComponentWrapper;
